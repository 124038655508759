<template>
    <div class="wrap">
       <div class="info-list">
           <div v-if="queryId == 0">
              <h1 class="bigtxt">中国工商银行 B2B 网上支付操作手册</h1>
              <div class="article">
                  如参考该文档仍不能完成支付或查询过程，请拨打中国工商银行
                  客服热线 95588 咨询或登陆工行官网 http://www.icbc.com.cn/查
                  询。
                 <p> 一、 B2B 支付开通流程</p>
                  <p class="text-indent24">企业只要满足以下条件，即可使用 B2B 网上支付功能。<br />
                  (一) 企业账户需要开通网上银行功能。开通成功后，根据商户需求
                  银行发放至少一个及以上 Usbkey，如申请一个 Usbkey 则无需
                  复核步骤；如申请两个及以上 Usbkey，则分别有制单、复核
                  权限。</p>
                 <p> (二) 登录工商银行网站下载并安装 Usbkey 驱动程序和网银管理工
                  具，正确设置网银登录环境。</p>
                  <p>二、 B2B 支付操作流程</p>
                  <p  class="text-indent24">(一) 制单流程</p>
                  <p  class="text-indent24">本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                  <p  class="text-indent50">1. 将制单员的 Usbkey 插入电脑 USB 接口。</p>
                  <p  class="text-indent50">2. 在商户网站选择商品或者服务后， 选择“中国工商银行” ，点
                  击“确认付款” 。</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img1.png" /></p>
                  <p class="text-indent50">3.	弹出验证U盾密码>对话框，输入Usbkey密码后，跳转到工行网上支付页面，选择支付单位与账号，确认订单信息无误后，记下订单号，点击“下一步”。
</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img2.png" /></p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img3.png" /></p>
                  <p class="text-indent50">4.	再次确认支付信息，点击“确定”。</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img4.png" /></p>
                  <p class="text-indent50">5.	输入Usbkey密码，弹出《核对签名信息》对话框，核对无误后，点击Usbkey上的OK键。</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img5.png" /></p>
                  <p class="text-indent50">6.	制单完成，等待复核人员复核订单。</p>
                  <p class="text-indent50">7.	如只申请一个Usbkey，则此步骤操作完成即支付成功，无需复核。</p>
                   <p class="text-center width70"><img src="../../assets/images/icbc-img6.png" /></p>
                   <p>(二) 复核流程</p>
                  <p  class="text-indent24">本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                   <p class="text-indent24"> 1.	插入复核员Usbkey，登录到工行官网,选择“企业网上银行登录”后,点击“U盾登录”,输入Usbkey密码进入企业网银。</p>
                   <p class="text-center width70"><img src="../../assets/images/icbc-img7.png" /></p>
                   <p  class="text-indent24">2.	在“付款业务->电子商务支付->批准指令”菜单下，输入查询条件，点击“确定”查询需要复核的订单。</p>
                   <p class="text-center width70"><img src="../../assets/images/icbc-img8.png" /></p>
                   <p  class="text-indent24">3.	查看需要复核的订单，点击“交易序号”可查看订单详细信息。确认订单信息无误后，点击“批准”，输入Usbkey密码，点击Usbkey上的OK键。
</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img9.jpg" /></p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img10.jpg" /></p>
                  <p  class="text-indent24">4.	显示指令授权成功，完成该笔订单复核。</p>
                  <p>三、 B2B支付交易银行订单号查询</p>
                  <p>(一)	登录工行企业网银。</p>
                  <p>(二)	在“付款业务->电子商务支付->查询指令”菜单下，输入查询条件，点击“确定”查询订单。点击“交易序号”可查看订单详细信息。“合同号”即为所需查询银行订单号。</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img11.jpg" /></p>
                  <p class="">(三)	订单中显示的“合同号”即为银行订单号。</p>
                  <p class="text-center width70"><img src="../../assets/images/icbc-img12.jpg" /></p>
              </div>
           </div>
           <div v-if="queryId == 1">
              <h1 class="bigtxt">光大银行 B2B 网上支付操作手册</h1>
              <div class="article">
                  <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打光大银行客服热线95595咨询或登光大银行官网http://www.cebbank.com查询。</p>
                  
                  <p>一、 B2B支付开通流程</p>
                  <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                  <p>(一)	企业账户需要开通企业版网银和电子商务功能。开通成功后，银行发放两个Usbkey，分别具有制单、复核权限。</p>
                  <p>(二)	登录光大银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                  <p>二、 B2B支付操作流程</p>
                  <p>(一) 制单流程</p>
                  <p class="text-indent24">本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                  <p>1.	将制单员的Usbkey插入电脑USB接口。</p>
                  <p>2.	在商户网站选择商品或者服务后，选择“中国光大银行”，点击“确认付款”。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img1.jpg" /></p>
                  <p>3.	跳转到光大银行企业网银登录页面，输入正确的信息后，点击“登录”，进入光大银行企业网银。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img2.jpg" /></p>
                  <p>4.	在光大银行主页点击“电子支付”，选择“待录入”订单，记录订单号，点击“支付”。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img3.jpg" /></p>
                  <p>5.	选择付款账号，确认订单信息无误后，点击“确定”。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img4.jpg" /></p>
                  <p>6.	核对Usbkey电子屏幕显示信息无误后，按下Usbkey上的“OK” 键。网银页面显示“交易已提交”，完成订单录入，等待复核人员复核。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img5.jpg" /></p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img6.jpg" /></p>
                  <p>(二) 复核流程</p>
                  <p class="text-indent24">本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                  <p>1.	插入复核员Usbkey，登录到光大银行官网，进入企业网银。</p>
                  <p>2.	选择“交易审核->转账类交易审核->本人审核->审核”，选择需要复核的订单，点击“同意”。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img7.jpg" /></p>
                  <p>3.	返回交易状态，显示交易成功，完成该笔订单复核。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img8.jpg" /></p>
                  <p>三、 B2B支付交易银行订单号查询</p>
                  <p>(一)	插入Usbkey，登录到光大银行官网，进入企业网银。</p>
                  <p>(二)	选择“电子支付->电子支付管理->支付明细查询”，选择按账号查询及相关查询条件，点击“查询”。“订单号”即为银行订单号。</p>
                  <p class="text-center width70"><img src="../../assets/images/ceb-img9.jpg" /></p>
                  <p>(三)	或联系光大银行对公人工客服（95595）登记后台查询。银行订单号一般由31位数字组成，其中前8位由交易日期组成，如交易在2019年01月20日生成，则所需查询银行订单号格式为20190120**********************。</p>
                 
              </div>
           </div>
          <div v-if="queryId == 2">
              <h1 class="bigtxt">华夏银行 B2B 网上支付操作手册</h1>
              <div class="article">
                  <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打华夏银行客服热线95577咨询或登陆华夏官网 http://www.hxb.com.cn/查询。</p>
                  <p>一、 B2B支付开通流程</p>
                  <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                  <p>(一)	企业账户需要开通企业版网银和电子商务功能。开通成功后，银行发放两个Usbkey，分别具有制单、复核权限。</p>
                  <p>(二)	登录华夏银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                  <p>二、 B2B支付操作流程</p>
                  <p>(一)	制单流程</p>
                  <p class="text-indent24">本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                  <p>1.	插入制单员的Usbkey，配置正确的浏览器设置。</p>
                  <p>2.	在商户网站选择商品或者服务，选择“华夏银行”，点击“确认付款”。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img1.jpg" /></p>
                  <p>3.	跳转到华夏银行电子支付页面，输入对应操作员的企业客户号、网银别名、登录密码后点击“下一步”。选择企业账号，点击“提交”。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img2.png" /></p>
                  <p>4.	制单完成，等待复核人员复核订单。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img3.jpg" /></p>
                  <p>(二)	复核流程</p>
                  <p class="text-indent24">本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                  <p>1.	插入复核员Usbkey，登录到华夏银行企业网银。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img4.jpg" /></p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img5.jpg" /></p>
                  <p>2.	选择“电子商务”->“B2B业务授权”，勾选所需授权订单，点击“同意授权”。确认无误后，点击“确认”。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img6.jpg" /></p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img7.jpg" /></p>
                  <p>3.	显示提交成功，完成该笔订单复核。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img8.jpg" /></p>
                  <p>三、 B2B支付交易银行订单号查询</p>
                  <p>(一)	插入Usbkey，登录到华夏银行企业网银。</p>
                  <p>(二)	选择“电子商务”->“B2B业务授权””->“B2B授权结果查询”，显示的“订单号码”即为所需查询银行订单号。</p>
                  <p class="text-center width70"><img src="../../assets/images/hxb-img9.jpg" /></p>
              </div>
          </div>
          <div v-if="queryId == 3">
             <h1 class="bigtxt">中国建设银行 B2B 网上支付操作手册</h1>
             <div class="article">
                    <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打建设银行客服热线95533咨询或登陆建行官网 http://www.ccb.com/查询。</p>
                    <p>一、 B2B支付开通流程</p>
                    <p>企业只要满足以下条件，即可使用B2B中的网上支付功能。</p>
                    <p>(一)	企业账户需要开通网上银行功能并成为企业网银高级版客户。开通成功后，银行发放三个网银盾，分别具有制单、复核、管理权限。</p>
                    <p>(二)	登录建设银行网站下载并安装网银盾驱动程序和网银管理工具，正确设置网银登录环境。</p>
                    <p>二、 B2B支付操作流程</p>
                    <p>(一)	制单流程</p>
                    <p class="text-indent24">本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                    <p>1.	插入建设银行网银盾，配置正确的浏览器设置。</p>
                    <p>2.	在商户网站选择商品或者服务后，选择“中国建设银行”，点击“确认支付”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img1.jpg" /></p>
                    <p>3.	跳转建行企业网银支付页面，输入“客户识别号”点击“确定”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img2.jpg" /></p>
                    <p>4.	插入网银盾，输入网银盾密码，确认订单信息和金额，点击“立即支付”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img3.jpg" /></p>
                    <p>5.	跳转建行企业网银，确认登录信息，输入密码，点击“登录”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img4.jpg" /></p>
                    <p>6.	进入到企业网银系统界面，点击“电子商务”->“网上支付”->“网上支付制单”，确认网上支付订单信息，选择账户，点击“确定”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img5.jpg" /></p>
                    <p>7.	选择下级复核员，输入“交易密码”。点击“确定”，确认支付，制单成功，等待复核人员复核订单。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img6.jpg" /></p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img7.jpg" /></p>
                    <p>(二)	复核流程</p>
                    <p>本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                    <p>1.	插入复核员网银盾，登录到建设银行企业网银。</p>
                     <p class="text-center width70"><img src="../../assets/images/ccb-img8.jpg" /></p>
                    <p>2.	点击 “电子商务”->“网上支付”->“网上支付复核”。选择待复核的支付订单，点击“确定”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img9.jpg" /></p>
                    <p>3.	订单信息核对无误后，选择“审核通过”，点击“确定”。输入网银盾密码，按下网银盾的“确认”键，确认复核。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img10.jpg" /></p>
                    <p>4.	提示“审核完成，支付成功”。</p>
                     <p class="text-center width70"><img src="../../assets/images/ccb-img11.jpg" /></p>
                    <p>三、 B2B支付交易银行订单号查询</p>
                    <p>(一)	登录到建设银行网上银行。</p>
                    <p>(二)	点击“电子商务”->“网上支付”->“支付流水查询”。选择相应起止时间或输入凭证号，选择付款账户，点击“确定”。</p>
                    <p class="text-center width70"><img src="../../assets/images/ccb-img12.jpg" /></p>
                    <p>(三)	“订单号码”即为所需查询银行订单号。点击“查询”即可查看建设银行网上银行交易电子回单。</p>
                     <p class="text-center width70"><img src="../../assets/images/ccb-img13.jpg" /></p>
                      <p class="text-center width70"><img src="../../assets/images/ccb-img14.jpg" /></p>
                    <p></p>
                    <p></p>
             </div>
          </div>
          <div v-if="queryId == 4">
              <h1 class="bigtxt">民生银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打民生银行客服热线95568咨询或登陆民生银行官网http://www.cmbc.com.cn/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需要开通企业版网银和电子商务功能。开通成功后，银行至少发放两个Usbkey，分别具有制单、复核权限。</p>
                <p>(二)	登录民生银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B支付操作流程</p>
                <p>(一) 制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将制单员的Usbkey插入电脑USB接口。</p>
                <p>2.在商户网站选择商品或者服务后，选择“民生银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img1.jpg" /></p>
                <p>3.	弹出《验证U宝口令》对话框，输入U宝口令后，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img2.jpg" /></p>
                <p>4.	进入民生银行支付界面，核对订单信息无误后，记下订单号，输入操作员密码，点击“确认”。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img3.jpg" /></p>
                <p>5.	确认交易信息及转账支付信息，选择付款账号，确认无误后，点击“确认”。弹出《验证U宝口令》对话框，输入U宝口令后，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img4.jpg" /></p>
                <p>6.	制单完成，等待复核人员复核订单。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img5.jpg" /></p>
                <p>(二) 复核流程</p>
                <p class="text-indent24">本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将复核员的Usbkey插入USB接口，登录到民生银行企业网银。</p>
                <p>2.	选择“审批”在“待办事宜”中查询所需复核订单，勾选“同意”选项框，点击“下一步”。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img6.jpg" /></p>
                <p>7.	点击“提交”，弹出《验证U宝口令》对话框，输入U宝口令后，点击“确定”。确认该笔订单复核。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img6.jpg" /></p>
                <p>8.	显示审批同意成功，完成该笔订单复核。</p>
                 <p class="text-center width70"><img src="../../assets/images/cmbc-img7.jpg" /></p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p>(一)	插入Usbkey，登录到民生银行企业网银。</p>
                <p>(二)	在“商户服务->B2B企业服务（直接支付）->查询订单”中查看交易记录，“订单编号”即为所需银行订单号，点击“查看” 可查看详细信息。</p>
                <p class="text-center width70"><img src="../../assets/images/cmbc-img8.jpg" /></p>
                <p>(三)	或联系民生银行对公人工客服（95568）登记后台查询。银行订单号一般由31位数字组成，其中前8位由交易日期组成，如交易在2019年01月20日生成，则所需查询银行订单号格式为20190120***********************。</p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 5">
              <h1 class="bigtxt">中国农业银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打中国农业银行客服热线95599咨询或登陆农行官网http://www.abchina.com/cn/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p>企业只要满足以下条件，即可使用B2B中的网上支付功能。</p>
                <p>(一)	企业账户需要开通企业网银。开通成功后，银行发放三个Usbkey(或四个Usbkey，2个管理员，2个操作员)，分别具有制单、复核、管理权限。
</p>
                <p>(二)	登录农业银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B支付操作流程</p>
                <p>(一)	制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入经办员（制单员）的Usbkey。</p>
                <p>2.在商户网站选择商品或者服务，选择“农业银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	进入农业银行支付页面，确认订单信息，在证书选择的下拉菜单中选择相应的证书，（若没有显示证书，点击检测证书）点击“下一步”，输入Usbkey密码，并确认。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img2.jpg" /></p>
                <p>4.	在支付账号的下拉菜单里选择相应的企业账号，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img3.jpg" /></p>
                <p>5.	输入Usbkey口令，并按照提示按下Usbkey的“OK”键完成交易。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img4.jpg" /></p>
                <p>6.	制单成功，等待复核。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img5.jpg" /></p>
                <p>(二)	复核流程</p>
                <p class="text-indent24">本操作流程由具有复核权限的财务操作员完成， 操作流程如下：</p>
                <p>1.	插入复核员Usbkey，登录到农行“企业网上银行”系统，选择证书登陆。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img6.jpg" /></p>
                <p>2.	在“复核发送”下选择“网上支付复核”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img7.jpg" /></p>
                <p>3.	核对相应需复核的订单交易信息，在右侧点选“通过”，点击下方“提交”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img8.jpg" /></p>
                <p>4.	确认将要提交的交易信息无误后，点击“提交”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img9.jpg" /></p>
                <p>5.	按照提示按下Usbkey的“OK”键完成交易，复核成功。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img10.jpg" /></p>
                <p>6.	在“复核发送”下选择“交易发送”，在“待发送情况列表”内点击“查询”网上支付发送。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img11.jpg" /></p>
                <p>7.	勾选相应需要发送的交易，核对信息无误后，点击下方“发送”，“确定”此次交易发送，按照提示按下Usbkey的“OK”键完成交易，交易发送成功。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img12.jpg" /></p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p>1.	插入对应Usbkey，登录农业银行“企业网上银行”系统。</p>
                <p>2.	选择“账户”下的“网上交易查询”，按照订单的交易类型、业务分类和起始时间选择查询，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img13.jpg" /></p>
                <p>3.	即可显示订单的全部信息，包含银行订单号。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img14.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 6">
             <h1 class="bigtxt">平安银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打平安银行客服热线95511-3咨询或登陆平安银行官网http://bank.pingan.com/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需要开通企业版网银和电子商务功能。开通成功后，银行发放两个Usbkey，分别具有制单、复核权限。</p>
                <p>(二)	登录平安银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境，建议使用IE浏览器。</p>
                <p>二、 B2B支付操作流程</p>
                <p>(一) 制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将制单员的Usbkey插入电脑USB接口。</p>
                <p>2.在商户网站选择商品或者服务，选择“平安银行”，确认订单信息无误后，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	跳转到平安银行支付页面，确认订单信息无误后，记下订单号，输入正确用户信息后，点击“立即登录”，弹出验证PIN码对话框，输入Usbkey密码，点击“登录”。在支付账号下拉菜单里选择相的企业账号，并核对订单信息，核对无误后点击“确定支付”。</p>
                <p class="text-center width70"><img src="../../assets/images/pa-img1.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/pa-img2.jpg" /></p>
                <p>4.	显示指令录入成功，本次制单完成。</p>
                <p class="text-center width70"><img src="../../assets/images/pa-img3.jpg" /></p>
                <p>(二) 复核流程</p>
                <p class="text-indent24">本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员Usbkey，登录到平安银行官网，登录企业网银。选择“交易授权->授权人员复核授权”点击“B2B网上支付”。</p>
                <p class="text-center width70"><img src="../../assets/images/pa-img4.jpg" /></p>
                <p>2.	找到该笔订单，点击“复核”，确认订单信息无误后，点击“复核通过”，完成订单复核。</p>
                <p class="text-center width70"><img src="../../assets/images/pa-img5.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/pa-img6.jpg" /></p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p class="text-indent24">目前暂不支持企业网银自助查询，请联系平安银行对公人工客服（95511-3），在其系统中名为快捷支付的位置查询由31位数字组成的订单号，其中前8位由交易日期组成，如交易是在2019年01月
20日产生，则银行订单号为20190120***********************。</p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 7">
             <h1 class="bigtxt">浦发银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打浦发银行客服热线95528咨询或登陆浦发银行官网http://www.spdb.com.cn/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需要开通企业版网银和电子商务功能。开通成功后，银行发放至少两个Usbkey，分别具有制单、复核权限。</p>
                <p>(二)	登录浦发银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B支付操作流程</p>
                <p>(一) 制单流程</p>
                <p class="text-indent24">本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将制单员的Usbkey插入电脑USB接口。</p>
                <p>2.在商户网站选择商品或者服务后，选择“浦发银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	跳转到浦发银行页面，确认订单详情无误后，记下订单号，点击“登录公司网银专业版”。弹出《选择证书》页面，选择证书, 点击“确认”，进入到浦发银行登录页面。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img1.jpg" /></p>
                <p>4.	输入对应的“企业客户号”（自动返显，若为空则请检查是否正确安装Usbkey驱动控件）、“用户登录名”、“网银登录密码” 后点击“登录”。弹出《验证U-KEY密码》页面，输入Usbkey密码，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img2.jpg" /></p>
                <p>5.	跳转到浦发银行《网上支付确认》页面，或选择“电子商务支付->支付”菜单下，选择要支付的订单点击“支付”。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img3.jpg" /></p>
                <p>6.	再次核对订单信息无误后，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img4.jpg" /></p>
                <p>7.	核对Usbkey上显示的信息无误后，按下Usbkey上的“OK”键。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img5.jpg" /></p>
                <p>8.	网银页面显示交易处理成功，完成制单，等待复核。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img6.jpg" /></p>
                <p>(二) 复核流程</p>
                <p>本操作流程由具复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员Usbkey，登录到浦发银行企业网银。</p>
                <p>2.	在“客户服务->我的信息台->网银交易授权”菜单下，选择需要复核的订单，点击“授权”。核对Usbkey上显示的信息无误后，按下Usbkey上的“OK”键，确认该笔订单复核。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img7.jpg" /></p>
                <p>3.	返回交易授权结果，显示交易成功，完成订单复核。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img8.jpg" /></p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p>(一)	插入Usbkey，登录到浦发银行企业网银。</p>
                <p>(二)	在“客户服务->我的信息台—网银交易日志查询”中查看交易记录。点击相应交易的“网银流水号”查看详细信息，其中</p>
                <p>“订单号”即为银行订单号。</p>
                <p class="text-center width70"><img src="../../assets/images/pf-img9.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/pf-img10.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 8">
             <h1 class="bigtxt">邮储银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p  class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打邮储银行客服热线95580咨询或登陆邮储银行官网http://www.psbc.com/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p>企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需要开通企业网上银行功能。开通成功后，银行发放至少两个Usbkey，分别具有制单、复核权限，Usbkey管理权限一般情况由银行进行统一管理。
</p>
                <p>(二)	登录邮储银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B支付操作流程</p>
                <p>(一) 制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将制单员的Usbkey插入电脑USB接口。</p>
                <p>2.	在商户网站选择商品或者服务后，选择“中国邮政储蓄银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	弹出验证Usbkey密码对话框，输入Usbkey密码后点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img1.jpg" /></p>
                <p>4.	进入邮储银行网上支付系统。确认订单信息无误后，输入客户编号、登录名称、登录密码，点击“登录付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img2.jpg" /></p>
                <p>5.	在邮储银行支付页面，付款账号的下拉菜单里选择相应的企业账号，点击“确认支付”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img3.jpg" /></p>
                <p>6.	在邮储银行支付页面，记录订单号码（该号码为此笔支付交易的唯一流水号），并再次确认订单信息无误，点击“确认支付”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img4.jpg" /></p>
                <p>7.	输入Usbkey密码，并确认Usbkey上的信息后，按下“OK”键完成交易。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img5.jpg" /></p>
                <p>8.	制单成功，等待复核人员复核订单。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img6.jpg" /></p>
                <p>(二) 复核流程</p>
                <p>本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员Usbkey，登录到邮储银行“企业网上银行专业版”。</p>
                <p>2.	在“付款业务”下选择“指令审核”，选择付款账户以及日期，点击查询。勾选需要复核的交易，点击“同意”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img7.jpg" /></p>
                <p>3.	核对需复核的订单交易信息，确认无误后，点击下方的“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img8.jpg" /></p>
                <p>4.	输入Usbkey密码，并确认Usbkey上的信息后，按下Usbkey 的“OK”键完成交易。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img9.jpg" /></p>
                <p>5.	页面跳转，显示交易处理成功，完成该笔订单复核。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img10.jpg" /></p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p>（一）登录邮储银行“企业网上银行”系统。（二）选择“付款业务”下的“指令查询”，选择相应查询条件，点击“查询”，再点击所查询交易的“交易流水号”。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img11.jpg" /></p>
                <p>（三）订单中显示的“订单号码”即为所需查询银行订单号。</p>
                <p class="text-center width70"><img src="../../assets/images/yz-img12.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 9">
             <h1 class="bigtxt">招商银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p  class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打招商银行客服热线95555咨询或登陆招行官网 http://www.cmbchina.com/查询。</p>
                <p>一、 B2B支付开通流程</p>
                <p>企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需开通企业网银功能和B2B支付功能。开通成功后，银行发放至少两个Usbkey，分别有制单、复核/管理权限。</p>
                <p>(二)	进入招商银行官网下载并安装U-BANK 客户端及商务支付安装程序，正确设置网银登录环境。</p>
                <p>(三)	使用管理员权限用户登录招商银行U-BANK，做如下设置：在“更多->商务支付->商户定制”菜单栏下，在所有商户列表中找到“M000001311-中金支付有限公司”点击“添加”，将中金支付有限公司添加至企业定制商户列表中，点击右下角“发送” 完成设置。
</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img1.jpg" /></p>
                <p>二、 B2B支付操作流程</p>
                <p>(一) 制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	将制单员的Usbkey插入电脑USB接口。在商户网站选择商品或者服务后，选择“招商银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>2.	跳转到招商银行企业银行公众版页面，核对订单信息无误后，选择“证书卡登录”，选择“登录名”（自动返显，若为空请点击页面下方“下载安全控件”下载并安装后重试），输入密码等相关信息后，点击“登录并支付”。
 </p>
                <p class="text-center width70"><img src="../../assets/images/zs-img2.jpg" /></p>
                <p>3.	进入到招商银行企业银行公众版，核对相关订单信息无误后，记下订单号，输入“用途”（必填）,点击“经办”。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img3.jpg" /></p>
                <p>4.	弹出对话框，再次确认订单信息，点击“确定”，完成制单，等待复核。注：如支付操作已设置为免复核模式，此步操作完成后即支付成功。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img4.jpg" /></p>
                <p>(二) 复核流程</p>
                <p>本操作流程由具复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员Usbkey，打开招商银行U-BANK客户端，UBank用户名自动返显，输入网银“登录密码”及“证书密码”，点击“登录”。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img5.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zs-img6.jpg" /></p>
                <p>2.	选择“更多->商务支付->订单审批”。</p>
                <p>3.	查询待审批的交易，选择该笔待审批的订单，确认订单信息无误后，点击页面右下角“同意”。弹出《确认》对话框，再次确认是否通过复核，确认无误后，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img7.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zs-img8.jpg" /></p>
                <p>4.	弹出审批结果页面，该笔订单显示“终审完毕”。复核过程完成，支付成功。</p>
                <p>三、 B2B支付交易银行订单号查询</p>
                <p>(一)	登录到招商银行U-BANK客户端。</p>
                <p>(二)	选择“更多->商务支付->订单查询”。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img9.jpg" /></p>
                
                <p>(三)	按照时间查询订单，此处查询到的“订单号”即为银行订单号。</p>
                <p class="text-center width70"><img src="../../assets/images/zs-img10.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 10">
             <h1 class="bigtxt">中国银行 B2B 网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打中国银行客服热线 95566。</p>
                <p>一、 B2B 支付开通流程 </p>
                <p>企业只要满足以下条件，即可使用 B2B 网上支付功能。</p>
                <p>(一)	企业账户需要开通企业版网银，并开通 B2B 电子商务功能。开 通成功后，银行发放两个 Usbkey，分别具有制单、复核权限。</p>
                <p>(二)	登录中国银行网站下载并安装 Usbkey 驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B 支付操作流程</p>
                <p>(一) 	制单流程 </p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入制单员的 Usbkey。</p>
                <p>2.	在商户网站选择商品或者服务，选择“中国银行”，点击“确认付款“</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	进入中国银行网上支付页面，输入对应操作员的用户名、密码、 验证码，点击“确定”，核对订单信息，点击“确定”，输入 Usbkey 密码，完成订单提交。
</p>
                <p class="text-center width70"><img src="../../assets/images/zg-img1.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zg-img2.jpg" /></p>
                <p>4.	登录中国银行官网，点击“企业客户网银登录”。 </p>
                <p class="text-center width70"><img src="../../assets/images/zg-img3.jpg" /></p>
                <p>5.	选择“CA 登录”输入密码，点击“登录”进入到中国银行网上银行。</p>
                <p class="text-center width70"><img src="../../assets/images/zg-img4.jpg" /></p>
                <p>6.	在网上银行中选择“电子商务-B2B 支付服务-订单复核”，选 择需要复核的订单号，点击“复核通过”。</p>
                <p class="text-center width70"><img src="../../assets/images/zg-img5.jpg" /></p>
                <p>7.	完成该笔订单确认，等待授权人员授权。 </p>
                <p class="text-center width70"><img src="../../assets/images/zg-img6.jpg" /></p>
                <p>(二) 	复核流程 </p>
                <p>本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员 Usbkey，登录到中国银行官网，进入到中国银行网上银行（前期所有登录操作同制单流程步骤 4、5）。点击“电子商务-B2B 支付服务-订单授权”，点击需要授权的订单号，查看订单的详细信息，点击“授权通过”。
</p>
                <p class="text-center width70"><img src="../../assets/images/zg-img7.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zg-img8.jpg" /></p>
                <p>2.	显示授权结果，点击“提交”，输入 Usbkey 密码，显示授权成功，完成订单授权。 </p>
                <p class="text-center width70"><img src="../../assets/images/zg-img9.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zg-img10.jpg" /></p>
                <p>三、 B2B 支付交易银行订单号查询  </p>
                <p>(一)	插入 Usbkey，登录到中国银行官网，进入中国银行网上银行。点击“电子商务-B2B 支付服务-订单查询”，选择“按组合条件查询”，选择相应查询条件，点击“查询”。
</p>
                <p class="text-center width70"><img src="../../assets/images/zg-img11.jpg" /></p>
                <p>(二)	显示出查询结果，订单号即为所需银行订单号。  </p>
                <p class="text-center width70"><img src="../../assets/images/zg-img12.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>
          <div v-if="queryId == 11">
             <h1 class="bigtxt">中信银行B2B网上支付操作手册</h1>
             <div class="article">
                <p class="text-indent24">如参考该文档仍不能完成支付或查询过程，请拨打中信银行客服热线 95558咨询或登陆中信官网 http://bank.ecitic.com/查询。</p>
                <p>一、 B2B 支付开通流程 </p>
                <p class="text-indent24">企业只要满足以下条件，即可使用B2B网上支付功能。</p>
                <p>(一)	企业账户需要开通网上银行和电子商务功能。开通成功后，银行发放至少两个Usbkey，分别有制单、复核权限。</p>
                <p>(二)	登录中信银行网站下载并安装Usbkey驱动程序和网银管理工具，正确设置网银登录环境。</p>
                <p>二、 B2B 支付操作流程</p>
                <p>(一)	制单流程</p>
                <p>本操作流程由具有制单权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入制单员的Usbkey。</p>
                <p>2.	在商户网站选择商品或者服务，选择“中信银行”，点击“确认付款”。</p>
                <p class="text-center width70"><img src="../../assets/images/abc-img1.jpg" /></p>
                <p>3.	选择对应的操作员证书，输入相应的“登录密码”和“PIN码”，点击“登录”。</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img1.jpg" /></p>
                <p>4.	确认支付信息，点击“确定”。</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img2.jpg" /></p>
                <p>5.	跳出提示对话框，显示制单成功，等待复核。网页显示审核处理中，即表示订单已经提交成功。</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img3.jpg" /></p>
                <p>(二)	复核流程 </p>
                <p>本操作流程由具有复核权限的财务操作员完成，操作流程如下：</p>
                <p>1.	插入复核员 Usbkey，下载中信银行公司网银客户端，选择对应的“用户名”，输入“密码”，“USB Key”密码，点击“登录”。 </p>
                <p class="text-center width70"><img src="../../assets/images/zx-img4.jpg" /></p>
                <p>2.	在“B2B电子商务-网上支付-审核”菜单下，选择相应的账户，点击“查询”，选择需要复核的指令，确认订单信息，点击“审核”。</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img5.jpg" /></p>
                <p class="text-center width70"><img src="../../assets/images/zx-img6.jpg" /></p>
                <p>3.	选择“同意”，点击“确定”，提示成功或者失败记录总数和总金额，即此笔订单已经支付完成。</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img7.jpg" /></p>
                <p>三、 B2B 支付交易银行订单号查询 </p>
                <p>(一)	插入对应Usbkey，登录中信银行公司网银客户端。</p>
                <p>(二)	在“B2B 电子商务-网上支付-查询”菜单下，点击“查询”，选择相应的账户，点击“查询”，选择需要查询的指令，点击“查看明细”，“订单号”即为所需银行订单号。 
</p>
                <p class="text-center width70"><img src="../../assets/images/zx-img8.jpg" /></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
             </div>
          </div>



       </div>
    </div>
</template>
<script>

export default {

     data() {
      return {
       queryId:''
      };
    },
    created(){
        this.getParams()
      },
    methods: {
      getParams(){
        var routerParams = this.$route.query.userId;
        console.log(routerParams)
        this.queryId = routerParams;
      }
    }
};
</script>
<style lang="less">
.info-list{
    width:70%;
    margin:40px auto;
}
.text-center{
  text-align: center;
}
.bigtxt{
  font-size: 30px;
  line-height:1.9;
  .text-center
}
.article{
  line-height: 2.2;
  padding-bottom: 80px;
}
.text-indent24{
  text-indent: 24px;
}
.text-indent50{
  text-indent: 50px;
}
.width70{
  width:70%;
  margin:10px auto;
}
</style>